<template>
  <div class="security-check">
    <div class="check-header">
      <div class="tips-text">
        <img src="@/assets/images/security-check/quote-left.png" class="quote-left"/>
        <p class="tips-title">安检提醒话术</p>
        <p class="tips-content">车辆安检可以确定车辆是否存在安全隐患，避免给驾乘人员和车辆造成严重伤害。</p>
        <img src="@/assets/images/security-check/quote-right.png" class="quote-right"/>
      </div>
      <div class="info-title">
        本次建议重点安检项目如下：
      </div>
      <img src="@/assets/images/security-check/security-check-icon.png" class="check-icon"/>
    </div>
    <div class="check-content-outer">
      <div class="check-content">
        <div class="check" v-if="observationProjectList.length > 0">
          <div class="check-title">
            <div class="title-icon"></div>
            <div class="title-txt">
              <span>上次未处理的</span>
              <span class="weight">留意观察</span>
              <span>项目</span>
            </div>
          </div>
          <div class="check-list">
            <div v-for="(item, index) of observationProjectList" class="check-item" :key="item">
              <span class="project-name">{{index+1}}.{{item.name}}</span>
              <span class="project-desc">[{{item.description}}]：</span>
              <span class="project-info">{{item.result}}</span>
            </div>
          </div>
        </div>
        <div class="check-gap" v-if="reminderProjectList.length > 0"></div>
        <div class="check" v-if="reminderProjectList.length > 0">
          <div class="check-title">
            <div class="title-icon"></div>
            <div class="title-txt">
              <span>本次基于</span>
              <span class="weight">里程推荐</span>
              <span>的检查项目</span>
            </div>
          </div>
          <div class="check-list">
            <div v-for="(item, index) of reminderProjectList" :key="item" class="check-item">
              <span class="project-name">{{index+1}}.{{item.name}}</span>
              <span class="project-desc" v-if="item.description">[{{item.description}}]</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuotesSecurityReminder } from 'api/user'
export default {
  data() {
    return {
      observationProjectList: [],
      reminderProjectList: []
    }
  },
  mounted() {
    const carCode = this.$route.query['carCode'] || ''
    const garageId = this.$route.query['garageId'] || ''
    const maintainMileage = this.$route.query['mileage'] || ''
    const requestData = {
      carCode,
      garageId,
      maintainMileage
    }
    this.getSecurityReminder(requestData)
  },
  methods: {
    async getSecurityReminder(requestData) {
      const res = await getQuotesSecurityReminder(requestData)
      if (res.data.success) {
        const observationProjectList = res.data.data.observationProjectList || []
        const reminderProjectList = res.data.data.reminderProjectList || []
        this.observationProjectList = observationProjectList
        this.reminderProjectList = reminderProjectList
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "~styles/index.scss";
.security-check{
  width: 100%;
  min-height: px2rem(700px);
  background: linear-gradient(to bottom, #DDE8ED, #E8EEF0);
}
.check-header{
  position: relative;
}
.check-header .tips-text{
  margin-left: px2rem(50px);
  padding-top: px2rem(50px);
  width: px2rem(480px);
  height: px2rem(180px);
  font-size: px2rem(28px);
  color: #363636;
  position: relative;
}
.tips-text .tips-title{
  line-height: px2rem(62px);
  height: px2rem(62px);
  font-size: px2rem(44px);
  color: #202020;
  font-weight: 600;
  background: url(~images/security-check/tips-bg.png) no-repeat;
  background-size: px2rem(264px) px2rem(30px);
  background-position: left bottom;
}
.tips-text .tips-content{
  margin-top: px2rem(10px);
  line-height: px2rem(36px);
}
.tips-text .quote-left{
  width: px2rem(24px);
  height: px2rem(16px);
  position: absolute;
  top: px2rem(30px);
  left: px2rem(-16px);
}
.tips-text .quote-right{
  width: px2rem(30px);
  height: px2rem(20px);
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.check-header .check-icon{
  width: px2rem(176px);
  height: px2rem(290px);
  position: absolute;
  top: px2rem(10px);
  right: 0px;
}
.check-header .info-title{
  margin-left: px2rem(50px);
  margin-top: px2rem(50px);
  font-size: px2rem(30px);
  color: #FC4C5A;
  font-weight: 600;
}

.check-content-outer{
  position: relative;
  margin-top: px2rem(10px);
}
.check-content-outer .check-content{
  position: absolute;
  left: px2rem(20px);
  width: px2rem(710px);
  max-height: px2rem(802px);
  background: #fff;
  border-radius: px2rem(24px);
  box-sizing: border-box;
  padding: px2rem(40px) px2rem(30px);
}
.check-content .check-gap{
  margin-top: px2rem(30px);
  margin-bottom: px2rem(30px);
  width: 100%;
  border-top: 1px dashed #A7A7A7;
}
.check .check-title{
  display: flex;
  align-items: center;
  margin-bottom: px2rem(8px);
}
.check-title .title-icon{
  width: px2rem(13px);
  height: px2rem(30px);
  border-top-right-radius: px2rem(6px);
  border-bottom-right-radius: px2rem(6px);
  background: #FF8200;
}

.check-title .title-txt{
  font-size: px2rem(34px);
  color: #202020;
  font-weight: 600;
  margin-left: px2rem(12px);
}
.title-txt .weight{
  color: #FF8200;
}
.check-item{
  font-size: px2rem(28px);
  font-weight: 600;
  line-height: px2rem(50px);
}
.check-item .project-name{
  color: #202020;
}
.check-item .project-desc{
  color: #999999;
}
.check-item .project-info{
  color: #FF8200;
}

</style>
